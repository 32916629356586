.navigation-link{
    text-decoration: none;
    color: white;
    font-size: 16px;
}

#logo{
    height: 65px;
    width: auto;
    object-fit: contain;
}

#navigation-bar{
    background: #343434;
}

/* Change the color of the hamburger icon */
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Adjusting the background color of the toggle button if needed */
.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.5);
}
