#banner-card{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 400px;
    border: none;
}

#banner-image{
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
}


.banner-heading{
    font-size: 3.5rem;
    text-transform: uppercase;
  }

  @media screen and (max-width: 630px) {
    .banner-heading {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 420px) {
    .banner-heading {
      font-size: 2.5rem;
    }
  }
  

  