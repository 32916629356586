body {
  margin: 0;
  font-family: "Abel", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* custom */

h1, h2, h3, h4, h5, h6{
  font-family: "Oswald", sans-serif;
}


p{
  font-family: "Abel", sans-serif;
  font-size: 18px;
}

.btn-primary{
  padding: 12px !important;
  background-color: #B288FF !important;
  border: whitesmoke !important;
  font-weight: bolder !important;
  color: whitesmoke !important;
}

.btn-primary:hover{
  padding: 12px !important;
  background-color: whitesmoke !important;
  border: #B288FF !important;
  font-weight: bolder !important;
  color: #B288FF !important;
}

.section{
  min-height: 400px;
  padding: 10px auto;
}

.big-heading{
  font-size: 2.5rem;
  text-transform: uppercase;
}

.section-image{
  height: 300px;
  width: 90%;
  object-fit: cover;
  border-radius: .375rem;
  margin-top: 10px;
}

.accordion-header > .accordion-button{
  background: #B3D8F9 !important;
}

/*
.accordion-header > button{ 
  color: whitesmoke !important;
}
*/

@media screen and (max-width: 630px) {
  .big-heading{
    font-size: 2rem;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 420px) {
  .big-heading{
    font-size: 1.75rem;
    text-transform: uppercase;
  }
}

.service_link{
  font-size: 14px;
  color: grey;
  text-decoration: none;
}
.font-awesome{
  font-size: 12px;
}